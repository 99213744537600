import Vue from 'vue'
import { apiGet, apiPost, apiDownload, refreshError } from '@/helpers/api'

const namespaced = true

const DEFAULT_INPUTS_STATE = {
    id: null,
    name: '',
    country_id: '',
    unit_type_id: '',
    year: '',
    update_allowed_time: '',
    source_id: '',
    current_state: '',
    last_seen: '',
    last_online_at: '',
    lifetime_utilization_fraction: 0,
    notes: '{}',
    begin: null,
}

const DEFAULT_CONNECTIVITY_SETTINGS = {
    connection_type: 'default_connection_type',
    configuration: '{}',
}

const state = () => ({
    inputs: { ...DEFAULT_INPUTS_STATE },

    actualUsage: [],
    plannedUsage: 0,

    connectivity_settings: {
        ...DEFAULT_CONNECTIVITY_SETTINGS,
    },
})

export const mutations = {
    SET_INPUT(state, { name, value }) {
        Vue.set(state.inputs, name, value)
    },

    UPDATE_UNIT_ACTUAL_USAGE(state, { data }) {
        state.actualUsage = _.flatten(data)
    },

    UPDATE_UNIT_PLANNED_USAGE(state, { value }) {
        state.plannedUsage = value
    },

    RESET_ITEM_STATE(state) {
        state.inputs = { ...DEFAULT_INPUTS_STATE }
        state.connectivity_settings = {
            ...DEFAULT_CONNECTIVITY_SETTINGS,
        }
    },

    UPDATE_CONNECTIVITY(state, { field, value }) {
        state.connectivity_settings[field] = value
    },
}

export const actions = {
    async getItemData({ commit, dispatch, state }, { itemId, vm, with_connectivity_settings = 0 }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(vm.route('unit.show', [itemId, with_connectivity_settings]))

            if (vm.isAdmin() && data.connectivity_settings) {
                commit('UPDATE_CONNECTIVITY', {
                    field: 'connection_type',
                    value: data.connectivity_settings.connection_type,
                })
                commit('UPDATE_CONNECTIVITY', {
                    field: 'configuration',
                    value: JSON.stringify(data.connectivity_settings.configuration),
                })
            }

            Object.keys(data).forEach((el) => {
                if (el === 'notes' && !data[el]) {
                    commit('SET_INPUT', {
                        name: el,
                        value: '{}',
                    })
                } else {
                    commit('SET_INPUT', {
                        name: el,
                        value: data[el],
                    })
                }
            })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async save({ state }, { vm }) {
        await apiPost(vm.route('unit.update', [state.inputs.id]), {
            ...state.inputs,
            update_allowed_time: state.inputs.update_allowed_time.toString(),
        })
    },

    async saveConnectivity({ dispatch, commit }, { vm, id, connection_type, configuration }) {
        try {
            dispatch('showLoader', null, { root: true })
            await apiPost(vm.route('unit.update_connectivity', id), {
                connection_type,
                configuration,
            })

            commit('UPDATE_CONNECTIVITY', {
                field: 'connection_type',
                value: connection_type,
            })
            commit('UPDATE_CONNECTIVITY', {
                field: 'configuration',
                value: configuration,
            })

            dispatch('hideLoader', null, { root: true })

            const notification = {
                type: 'success',
                text: vm.$t('§ui.notification_success_saved'),
            }
            dispatch('notification/add', notification, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async fetchUnitUseData({ commit, dispatch }, { vm, unit, grouping }) {
        try {
            const { name, source_id, begin, actual_usage, planned_usage } = await apiGet(
                vm.route('unit.use_data_chart', { unit, grouping })
            )

            commit('SET_INPUT', {
                name: 'id',
                value: unit,
            })

            commit('SET_INPUT', {
                name: 'name',
                value: name,
            })

            commit('SET_INPUT', {
                name: 'source_id',
                value: source_id,
            })

            commit('SET_INPUT', {
                name: 'begin',
                value: begin,
            })

            commit('UPDATE_UNIT_ACTUAL_USAGE', {
                data: actual_usage,
            })

            commit('UPDATE_UNIT_PLANNED_USAGE', {
                value: planned_usage,
            })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async downloadUnitUseExport(context, { vm, unit, grouping }) {
        await apiDownload(
            vm.route('unit.use_data_export', {
                unit,
                grouping,
                export: 'excel',
            })
        )
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
